(function ($) {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KTZF7MW');

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'priorityDataLayerIsReady',
        'hit': {
            'timestamp_page_impression': Date.now(),
        },
        'user': {}
    });

    dataLayer.push({
        'event': 'secondaryDataLayerIsReady',
        'hit': {
            'page_template': dataLayerResources.template,
        },
        'user': {
            'browser_language': navigator.language,
            'website_language': dataLayerResources.locale,
        }
    });
})(jQuery);