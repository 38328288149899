const searchSuggestions = () => {
  const searchBar = $('#searchbar-form input[type="search"]');
  const suggestionList = $(".searchbar-suggestions");

  // When the user enters text in the search field
  // We try to find matching items
  const onInput = e => {
    const search = searchBar.val().replace(/\W/g, "");
    searchBar.val(search);

    if (!search || search.length < 1 || e.key === "Escape")
      return hideSuggestionList();

    let results = 0;
    let regex = "(^|)(";
    for (const letter of search.split("")) regex += letter || " ";
    regex += ")(|$)";

    suggestionList
      .find("li")
      .hide()
      .removeClass("active")
      .each(function (i, link) {
        if (
            ($(link).data("name").indexOf(searchBar.val().toLowerCase())) == 0
        ) {
          $(link).show();
          results++;
        }
      });

    if (results > 0) showSuggestionList();
    else hideSuggestionList();
  };

  const showSuggestionList = () => {
    searchBar.addClass("suggestions-visible");
    const width = Math.round(
      searchBar.outerWidth(true) +
        parseInt(searchBar.css("borderLeftWidth"), 10)
    );
    suggestionList.css("width", width).slideDown(100);
  };

  const hideSuggestionList = () => {
    setTimeout(() => {
      searchBar.removeClass("suggestions-visible");
      suggestionList.fadeOut(100).find("li").hide();
    }, 100);
  };

  const onKeydown = e => {
    const results = suggestionList.find("li:visible");

    if (results.length < 1) return;

    const current = results.has("a.active");
    const currentIndex = results.index(current);

    let newIndex;

    // If user press Enter, we follow the active suggestion link
    if (e.key === "Enter" && currentIndex > -1) {
      e.preventDefault();
      window.location.href = current.find("a").attr("href");
    }


    // If user press arrow up or arrow down keys, we change the active suggestion
    if (e.key === "ArrowDown") {
      newIndex = currentIndex === results.length - 1 ? -1 : currentIndex + 1;
    }
    if (e.key === "ArrowUp") {
      newIndex = currentIndex === 0 ? -1 : currentIndex - 1;
    }

    results.find("a.active").removeClass("active");
    $(results[newIndex]).find("a").addClass("active");
  };

  searchBar
    .on("input", onInput)
    .on("blur", hideSuggestionList)
    .on("keydown", onKeydown);

  $(window).resize(hideSuggestionList);
};

// Toggle filters on button click
const searchFilters = () => {
  const button = $(".searchbar-show-filters");
  const filters = $(".searchbar .searchbar-filters");
  let vars = get_url_vars();

  jQuery.each(vars, function (index, value) {
    let input = $("input[name='" + value + "'][value='" + vars[value] + "']");
    input.closest("fieldset").addClass("visible");
    input.prop("checked", true);
  });

  if(vars.length === 1 && !vars.includes("search") || vars.length > 1){
    scrollToTimeout($("#searchbar-form"));
    if($(window).width() > 575){
      button.toggleClass("active");
      filters.slideToggle(100);
    }
  }

  if((vars.length === 1 && vars["search"])){
    scrollToTimeout($("#searchbar-form"));
  }

  button.on("click", e => {
    e.preventDefault();
    button.toggleClass("active");
    filters.slideToggle(100);
  });
};

// Customize input range
const initRange = range => {
  const name = range.attr("name");
  const min = parseInt(range.attr("min"), 10);
  const max = parseInt(range.attr("max"), 10);
  const label = $(`label[for="${name}"]`).find("span").eq(1);

  const setRange = () => {
    const value = parseInt(range.val(), 10);
    const left = `${Math.round((value / max) * 100)}%`;

    label.text(value === min || value === max ? "" : value);
    label.css("left", left);

    range
      .val(value)
      .css(
        "background",
        `linear-gradient(to right, white ${left}, rgba(255, 255, 255, 0.25) ${left}`
      );
  };

  setRange();
  range
    .on("input", setRange)
    .on("change", setRange)
    .on("focus, input", () => {
      label.show().delay(1000).fadeOut(100);
    });
};

const collapseFieldset = fieldset => {
  const section = fieldset.find("section");
  const legend = fieldset.find("legend");
  const button = $(".reset-filters button.intent-btn");

  const disableInputs = () => {
    section
      .slideUp(100)
      .find("input")
      .each((i, input) => {
        input.checked = false;
        // input.value = null;
        input.disabled = true;
      });
  };
  const enableInputs = () => {
    section
      .slideDown(100)
      .find("input")
      .each((i, input) => {
        input.disabled = false;
      });
  };

  if (!fieldset.hasClass("visible")) disableInputs();

  legend.on("click", () => {
    if (fieldset.hasClass("visible")) {
      fieldset.removeClass("visible");
      disableInputs();
    } else {
      fieldset.addClass("visible");
      enableInputs();
    }
  });

  button.on("click", () => {
    if (fieldset.hasClass("visible")) {
      fieldset.removeClass("visible");
      disableInputs();
    }
  });

};


function get_url_vars() {
  let vars = [], hash;
  let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (let i = 0; i < hashes.length; i++) {
    if(hashes[i].includes("=")){
      hash = hashes[i].split('=');
      vars.push(decodeURIComponent(hash[0]));
      if(1 in hash){
        hash[1] = hash[1].replace("+", " ");
        vars[decodeURIComponent(hash[0])] = decodeURIComponent(hash[1]);
      }
    }
  }
  return vars;
}

const scrollToTimeout = element => {
  setTimeout(function (){
    $('html,body').animate({scrollTop: element.offset().top-100}, 'slow');
  },700)
}


$(document).ready(() => {
  searchSuggestions();
  searchFilters();

  document
    .querySelectorAll("fieldset.collapsible")
    .forEach(fieldset => collapseFieldset($(fieldset)));

  document
    .querySelectorAll('#intent-ready input[type="range"]')
    .forEach(range => initRange($(range)));
});
