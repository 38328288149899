// Show tooltip on mouseover
$(".intent-tooltip").parent().addClass("parent-tooltip");

$("body").on("mouseover", ".intent-tooltip", e => {
    const tooltip = $(e.target);
    if (
        $(tooltip).next(".intent-tooltip-message").length === 0 &&
        !$(tooltip).hasClass("active-tooltip")
    ) {
        $(tooltip).addClass("active-tooltip");
        const message = $(tooltip).attr("aria-label");
        const html = `<div class="intent-tooltip-message">${message}</div>`;
        $(html).insertAfter($(tooltip)).fadeIn(10);
    }
});

// Hide tooltip on mouseleave
$("body").on("mouseleave", ".intent-tooltip", e => {
    const tooltip = $(e.target);
    $(tooltip)
        .next(".intent-tooltip-message")
        .delay(10)
        .fadeOut(100, () => tooltip.next(".intent-tooltip-message").remove());
    $(tooltip).removeClass("active-tooltip");
});
