// // Display a gray background instead of undefined images
const imageFallback = () => {
  document.querySelectorAll("img").forEach(img => {
    img.onerror = () => {
      img.src =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2MIDQ39DwAEAQH/Gv0A5QAAAABJRU5ErkJggg==";
    };
  });
};

new MutationObserver(imageFallback).observe(window.document.body, {
  childList: true,
});
