$(document).ready(() => {
  let uploader;

  // Upload an image
  $("#intent-ready").on("click", ".provider-upload-image", function (e) {
    e.preventDefault();

    const preview = $(this);
    const input = $(`input#${preview.attr("for")}`);

    // If only one uploader in page and the media frame already exists, just reopen it.
    if ($("#intent-ready .provider-upload-image").length === 1 && uploader)
      return uploader.open();

    // Create a new media frame
    uploader = wp.media({
      type: "image",
      title: "Sélectionnez ou téléchargez un logo",
      button: {
        text: "Choisir ce logo",
      },
      multiple: false,
    });

    // When an image is selected in the media frame
    uploader.on("select", () => {
      const { url } = uploader.state().get("selection").first().toJSON();

      input.val(url);
      preview.css("backgroundImage", `url(${url})`);
    });

    // Finally, open the modal on click
    uploader.open();
  });
});
