const messages = {};

const rules = {
  nameProvider: {
    required: true,
    minlength: 2,
    maxlength: 50,
  },
  intent_sandbox_id: {
    required: true,
    maxlength: 30,
  },
  intent_id: {
    required: true,
    maxlength: 30,
  },
  "profession[]": {
    required: true,
  },
  website_url: {
    url: true, // url with http or https
  },
  address_2: {
    minlength: 2,
    maxlength: 50,
    pattern: /^[a-zA-ZÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\s-]+$/, // lettres, tiret et espace,
  },
  "connected_entities[]": {
  },
  contracts_nb: {
    min: 0,
    max: 999,
  },
  use_case: {
  },
  "flow_frequency[]": {
  },
};

// Specific rules for contact (10 max)
for (let i = 0; i < 10; i++) {
  rules[`contact_${i}_name`] = {
    minlength: 2,
    maxlength: 30,
    pattern: /^[a-zA-ZÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\s-]+$/, // lettres, tiret et espace,,
  };
  rules[`contact_${i}_function`] = {
    minlength: 2,
    maxlength: 40,
  };
  rules[`contact_${i}_email`] = {
    email: true,
  };
  rules[`contact_${i}_phone`] = {
    pattern: /^[0-9]{10}( \/ [0-9]{10})?$/, // "0600112233" or "0600112233 / 03205112233"
  };
  messages[`contact_${i}_phone`] = {
    pattern: "Format incorrect (1 ou 2 numéros de téléphone séparés par un /)",
  };
}

const errorPlacement = (error, element) => {
  const item = element.parents(".provider-form-item");
  const block = item.parents(".provider-section");

  item.addClass("error").append(error);

  if (!block.hasClass("visible")) {
    block.find("h2").click();
  }
};

$(document).ready(() => {
  if ($("#provider-edit").length && $.fn.validate) {
    $("#provider-edit").validate({
      rules,
      messages,
      lang: "fr",
      ignore: "",
      errorPlacement,
    });

    $("#provider-edit")
      .find("input")
      .on("keyup, change", function () {
        $(this)
          .parents(".provider-form-item")
          .toggleClass("error", !$(this).valid());
      });
  }
});
