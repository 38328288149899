document.body.classList.add("intent2021");
window.$ = jQuery;

jQuery(document).ready(() => {
  console.log("Document is ready, and jQuery ($) is available");

  // Prevent click on link with # href
  $('#menu-menu-principal .menu-item-has-children a[href="#"]').on(
    "click",
    e => {
      e.preventDefault();
    }
  );
});
