jQuery('.searchbar-filters fieldset section input').change(function () {

    let url = document.location.href;
    this.value = this.value.replaceAll(" ", "+");
    let param_name = "&" + this.name;
    let param_val = "=" + this.value;
    let url_modified = "";

    url.indexOf("search") === -1 ? url_modified = url + "?search=" + param_name + param_val : url_modified = url + param_name + param_val;
    url = decodeURIComponent(url);
    let modification = get_modification_type(this, url, param_name, param_val);

    if (modification === "add") url = url_modified;
    else if (modification === "remove") url = url.replaceAll(param_name + param_val, '');
    else if (modification === "change") url = url.replace(new RegExp("(.+" + param_name + ")(=\\d+)(.*)"), "$1" + param_val + "$3");
    else return false;

    document.location = url;
});

function get_modification_type(elt, url, param_name, param_val) {
    let modification = "";

    if (url.indexOf(param_name) === -1) {
        modification = "add";
    } else {
        modification = "change";

        if (elt.checked){
            if(url.indexOf(param_name + param_val) !== -1){return}
            if(param_name === '&badge'){modification = "add"}
        }
        else{
            if($(elt).attr('type') !== 'range'){modification = "remove"}
        }
    }
    return modification;
}

