/**
 * HTML SYNTAX :
 *
 *  <label class="intent-switch" data-on="/url-to-fech.php?state=on" data-off="/url-to-fech.php?state=off">
 *    <input type="checkbox" checked>
 *    <span>Label du switch</span>
 *  </label>
 *
 *  OR (javascript action)
 *  <label class="intent-switch" data-type="action" data-on="actionOn" data-off="actionOff">
 *    <input type="checkbox" checked>
 *    <span>Label du switch</span>
 *  </label>
 */

$(document).ready(() => {
  const onError = error => {
    console.error(error);
    window.location.reload();
  };

  const onSuccess = response => {
    console.log(response);
  };

  $("body").on("change", ".intent-switch", e => {
    const checked = e.target.checked;
    const { on, off, type } = e.target.parentNode.dataset;

    if (!on && !off) return;

    if (type === "action") {
      eval(checked ? on : off)();
    } else {
      fetch(checked ? on : off)
        .then(onSuccess)
        .catch(onError);
    }
  });
});
