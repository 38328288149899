$(document).ready(() => {

    const collapse = (title, section, content) => {
        if (!section.hasClass("visible")) content.hide();

        title.on("click", e => {
            //  ¯\_(ツ)_/¯ had to do this to make it work with fieldsets !
            if (section.hasClass("visible"))
                setTimeout(() => {
                    section.removeClass("visible");
                    content.slideUp(100);
                }, 0);
            else
                setTimeout(() => {
                    section.addClass("visible");
                    content.slideDown(100);
                }, 0);
        });
    };

    // Collapse sections
    $(".provider-section > h2").each((i, title) => {
        collapse(
            $(title),
            $(title).closest(".provider-section"),
            $(title).closest(".provider-section").find("section")
        );
    });

    // Collapse fieldset
    $("fieldset.collapsible > legend").each((i, title) => {
        collapse(
            $(title),
            $(title).closest("fieldset.collapsible"),
            $(title).closest("fieldset.collapsible").find("div")
        );
    });

    $("fieldset.collapsible > span").each((i, title) => {
        collapse(
            $(title),
            $(title).closest("fieldset.collapsible"),
            $(title).closest("fieldset.collapsible").find("div")
        );
    });

    // Init datetime picker
    if ($("#provider-edit").length && $.fn.validate) {
        $("#provider-edit .flatpickr").flatpickr({
			allowInput: true,
            enableTime: false,
            dateFormat: "d/m/Y",
            minDate: "01.01.2000",
            maxDate: "today",
            locale: {
                weekdays: {
                    shorthand: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ve', 'Sam', 'Dim'],
                    longhand: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
                },
                months: {
                    shorthand: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
                    longhand: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                },
            },
        });
    }

    // Count connected_entitie checkboxes
    function countConnectedApis() {
        const count = $(`[name='connected_entities[]']:checked`).length;
        const counter = $("#connected_entities_nb");
        counter.val(count);
    }

    // Focus on error input on click on error message
    function focusErrorItem(e) {
        e.preventDefault();
        console.log($(`[name="${this.innerHTML}"]`).eq(0));
        $(`[name="${this.innerHTML.trim()}"]`).eq(0).focus();
    }

    // manage "Add" buttons
    function addItem(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this?.dataset?.action === "addContact") addContact($(this));
        if (this?.dataset?.action === "addCheckbox") addCheckbox($(this));
    }

    function addCheckbox(button) {
        const lastItem = button.prev("input[type='checkbox']");
        const name = lastItem.attr("name");

        // On desactive le bouton
        button.attr("disabled", "disabled");

        // on insère un champs de saisie
        const inputText = $("<input type='fake' />");

        // Lorsque l'utilisateur tape entrée,
        inputText.on("keypress", function (e) {
            inputText.removeClass("error").next("span.error").remove();

            if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();

                const value = $(this).val().trim();

                // On vérifie que le texte saisi existe pas déja
                const allValues = Array.from(
                    document.querySelectorAll(`[name="${name}"]`)
                ).map(checkbox => checkbox.value.toLowerCase());

                if (allValues.includes(value.toLowerCase())) {
                    inputText
                        .addClass("error")
                        .after('<span class="error">Ce nom est déja utilisé</span>');

                    return;
                }

                // Si il existe pas
                const checkbox = $(
                    `<input type="checkbox" name="${name}" value="${value}" data-label="${value}" checked>`
                );
                const removeBtn = $(
                    '<button class="provider-remove-item"><i class="fa fa-times" aria-hidden="true"></i></button>'
                ).on("click", () => {
                    checkbox.remove();
                    removeBtn.remove();
                    $("#intent-ready").trigger(`${name}_changed`);
                });

                $(this).after(checkbox).after(removeBtn);
                $(this).removeClass("error").next("span.error").remove();
                $(this).remove();
                button.removeAttr("disabled");

                $("#intent-ready").trigger(`${name}_changed`);
            }
        });

        inputText.on("blur", function (e) {
            $(this).next("span.error").remove();
            $(this).remove();
            $("#intent-ready").trigger(`${name}_changed`);
            button.removeAttr("disabled");
        });

        lastItem.after(inputText);
        inputText.focus();
    }

    function addContact(button) {
        const row = button.prev(".provider-row");
        const nbItems = $("#intent-ready .provider-contact").length;
        const html = row
            .html()
            .replace(/contact_(\d)_/gi, `contact_${nbItems}_`)
            .replace(/value="(.*)"/gi, `value=""`);

        row.after(`<div class="provider-row provider-contact">${html}</div>`);
        addButtonRemoveContact($(".provider-row.provider-contact").last());
    }

    function addButtonRemoveContact(contact) {
        const removeBtn = $(
            '<button class="provider-remove-item"><i class="fa fa-times" aria-hidden="true"></i></button>'
        ).on("click", e => {
            e.preventDefault();
            e.stopPropagation();
            let contact_to_remove = $(e.target).parents(".provider-row.provider-contact");
            let is_first_contact = contact_to_remove.hasClass("first_contact");
            let siblings = contact_to_remove.siblings('.provider-row.provider-contact')
            let has_next_sibling = siblings.length > 0;

            if (is_first_contact && has_next_sibling) {
                siblings.addClass("first_contact");
            }

            if (is_first_contact && !has_next_sibling) {
                contact_to_remove.find("input").val("");
            } else {
                contact_to_remove.remove();
            }

            hideMoreContactButton();
        });
        contact.append(removeBtn);
    }

    function hideMoreContactButton() {
        const nbContact = $("[name^=contact_]").length / 4;
        const button = $(".provider-add-item[data-action='addContact']");

        button.toggle(nbContact < 10);
    }

    hideMoreContactButton();

    if($(".provider-row.provider-contact").hasClass("contact_0")){
        $(".provider-row.provider-contact").addClass("first_contact");
    }
    addButtonRemoveContact($(".first_contact"));

    $("#intent-ready")
        .on("change", "[name='connected_entities[]']", countConnectedApis)
        .on("connected_entities[]_changed", countConnectedApis)
        .on("click", ".provider-add-item", addItem)
        .on("click", ".provider-message--error b", focusErrorItem);

    var title = $("#namepage").val();
    if (typeof title !== 'undefined') {
        if (document.title != title) {
            document.title = title;
        }
        $('meta[name="description"]').attr("content", newDescription);
    }

    var body = $("html, body");
    body.stop().animate({scrollTop: 0}, 500, 'swing');

});
