const initProvidersSlider = () => {
  document.querySelectorAll(".providers-slider").forEach(list => {
    $(list)
      .find(".providers-slider-items0")
      .addClass("ready")
      .slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
        arrows: true,
        lazyLoad: "ondemand",
        prevArrow: $(list).find('button[data-direction="prev0"]'),
        nextArrow: $(list).find('button[data-direction="next0"]'),
        responsive: [
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: false,
            },
          },
        ],
      });
  });
};

$(document).ready(initProvidersSlider);
const initProvidersSlider2 = () => {
    document.querySelectorAll(".providers-slider").forEach(list => {
        $(list)
            .find(".providers-slider-items1")
            .addClass("ready")
            .slick({
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                dots: true,
                arrows: true,
                lazyLoad: "ondemand",
                prevArrow: $(list).find('button[data-direction="prev1"]'),
                nextArrow: $(list).find('button[data-direction="next1"]'),
                responsive: [
                    {
                        breakpoint: 1080,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 420,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: false,
                        },
                    },
                ],
            });
    });
};

$(document).ready(initProvidersSlider2);
const initProvidersSlider3 = () => {
    document.querySelectorAll(".providers-slider").forEach(list => {
        $(list)
            .find(".providers-slider-items2")
            .addClass("ready")
            .slick({
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                dots: true,
                arrows: true,
                lazyLoad: "ondemand",
                prevArrow: $(list).find('button[data-direction="prev2"]'),
                nextArrow: $(list).find('button[data-direction="next2"]'),
                responsive: [
                    {
                        breakpoint: 1080,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 420,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: false,
                        },
                    },
                ],
            });
    });
};

$(document).ready(initProvidersSlider3);