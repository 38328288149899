$(document).ready(() => {
  const buttons = $(".intent-tabs-buttons button");
  const tabs = $("[id^='tab-']").hide();

  // Show first tab
  buttons.first().addClass("current");
  $("#tab-" + buttons.first().data("tab")).show();

  // Change tab on click on button
  buttons.on("click", e => {
    const thisButton = $(e.target);
    const thisPage = $("#tab-" + thisButton.data("tab"));

    if (!thisButton.hasClass("current")) {
      buttons.removeClass("current");
      thisButton.addClass("current");

      tabs.hide();
      thisPage.fadeIn(200);
    }
  });
});
