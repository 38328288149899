$(window).load(() => {
  const header = $("#intent-header");
  const adminBar = $("#wpadminbar");
  const mobileMenu = header.find(".et_mobile_menu");
  const menuButton = mobileMenu.parents("a.mobile_nav");
  const linksWithChildren = mobileMenu.find(".menu-item-has-children > a");
  const subMenus = mobileMenu.find(".sub-menu");

  // Get header height
  function getHeaderHeight() {
    const headerHeight = header.outerHeight() || 0;
    const padding = parseInt(header.css("padding-top") || 0, 10);
    const adminBarheight = Math.max(
      (adminBar.outerHeight() || 0) - window.scrollY,
      0
    );

    return Math.round(headerHeight + adminBarheight + padding - 20);
  }

  // Set mobile menu height
  function setMenuHeight() {
    mobileMenu.css(
      "bottom",
      -1 * Math.round($(window).outerHeight() - getHeaderHeight())
    );
  }

  // Toggle mobile menu sub menus.
  function toggleSubMenus() {
    // Close all submenus when menu is open
    if (header.length > 0)
      new MutationObserver(() => {
        if (menuButton.hasClass("opened")) {
          setMenuHeight();
          $("body").css({
            height: "100vh",
            overflow: "hidden",
          });
        } else {
          closeSubMenus();
          $("body").css({
            height: "auto",
            overflow: "auto",
          });
        }
      }).observe(mobileMenu.get(0), { attributes: true });

    // Toggle submenus
    linksWithChildren.off("click").on("click", e => {
      e.preventDefault();
      closeSubMenus();
      $(e.target).next(".sub-menu").addClass("visible");
    });
  }

  // Add a back link on each sub menu
  function addBackLinks() {
    linksWithChildren.each((i, link) => {
      $(link)
        .next(".sub-menu")
        .prepend(
          `<li class="menu-item sub-menu-back-link"><a href="#back">${link.innerText}</a></li>`
        );
    });
  }

  // Close submenus
  function closeSubMenus(e) {
    e && e.preventDefault();
    subMenus.each((i, item) => $(item).removeClass("visible"));
  }

  // Set main content a mon height (to fit footer)
  function setContentMinHeight() {
    $("#main-content").css(
      "min-height",
      Math.round(document.documentElement.clientHeight - getHeaderHeight())
    );
  }

  /*
  if (
    window?.parent?.document?.body &&
    !window?.parent?.document?.body.classList.contains("wp-admin")
  ) {
    addBackLinks();
    toggleSubMenus();
    setMenuHeight();
    setContentMinHeight();

    subMenus.on("click", ".sub-menu-back-link", closeSubMenus);
    $(window).on("scroll resize", setMenuHeight);
    $(window).on("resize", setContentMinHeight);
  }*/
  
	if(jQuery(window).width() > 768) {
		jQuery('.mega-sub-menu').find('li.mega-menu-column:first').each(function() {
			var height = jQuery(this).height();
			if(height > jQuery(this).next().height()) {
				jQuery(this).next().css('height', height+15 + "px");
			} else {
				jQuery(this).next().css('height', jQuery(this).next().height()+15 + "px");
			}
		});
	}
	
	jQuery('.close-preheader').click(function() {
		jQuery('#preheader').remove();
		
		const ajaxurl = jQuery(this).data('ajaxurl');

		const data = {
			action: jQuery(this).data('action'), 
			nonce:  jQuery(this).data('nonce'),
		}
		
		fetch(ajaxurl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Cache-Control': 'no-cache',
			},
			body: new URLSearchParams(data),
		});
	});
	
	jQuery('.content-preheader').each(function() {
		
		const ajaxurl = jQuery(this).data('ajaxurlcache');

		const data = {
			action: jQuery(this).data('action'), 
			nonce:  jQuery(this).data('nonce'),
		}
		
		fetch(ajaxurl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Cache-Control': 'no-cache',
			},
			body: new URLSearchParams(data),
		})
		.then(response => response.json())
		.then(body => {
			if(body.data == "remove") {
				jQuery('#preheader').remove();
			}
		});
		
		
		
	});
	
});
